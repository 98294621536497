import React from 'react';

import styles from '@styles/Footer/download_app.module.scss';

type Props = {
  text: string;
  title: string;
  url: string;
  icon: JSX.Element;
};

const DownloadApp: React.FC<Props> = ({ text, title, url, icon }) => (
  <a href={url} target="_blank" rel="noopener noreferrer" className={styles.container}>
    <div className={styles.container__logo}>{icon}</div>

    <div className={styles.container__content}>
      <p className={styles.content__text}>{text}</p>
      <p className={styles.content__title}>{title}</p>
    </div>
  </a>
);

export default DownloadApp;
