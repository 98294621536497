import React from 'react';
import { useIntl } from 'react-intl';
import styles from '@styles/Footer/copyright.module.scss';
import { useConfig } from 'providers';

const Copyright: React.FC = () => {
  const { mdcLandingUrl } = useConfig();
  const marineDataCloudUrl = (
    <a href={mdcLandingUrl} target="_blank" rel="noopener noreferrer">
      Marine Data Cloud™
    </a>
  );

  const { formatMessage } = useIntl();
  const f = (id: string, currentYear: number) => formatMessage({ id }, { currentYear, marineDataCloudUrl });

  return (
    <div className={styles.container}>
      <p className={styles.container__text}>{f('theboat_app.footer.copyright', new Date().getFullYear())}</p>
    </div>
  );
};

export default Copyright;
