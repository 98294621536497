/* eslint-disable object-curly-newline */
import { LeftOutlined } from '@ant-design/icons';
import { Button, Drawer, Grid } from 'antd';
import Link from 'next/link';
import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { HeaderLogoIcon } from '../CustomIcons';

type Props = {
  onClose: () => void;
  visible: boolean;
  className?: string;
  goBack?: boolean;
  head?: JSX.Element;
  closable?: boolean;
  children: React.ReactNode;
};

const MainDrawer: FC<Props> = ({ onClose, visible, className, goBack, children, head, closable = true }) => {
  const { formatMessage } = useIntl();
  const f = (id: string): string => formatMessage({ id });

  const { lg } = Grid.useBreakpoint();

  useEffect(() => {
    if (lg) {
      onClose();
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lg]);

  return (
    <Drawer
      title={
        head || (
          <Link href="/" className="headerLogo">
            <HeaderLogoIcon />
          </Link>
        )
      }
      placement="left"
      className={className}
      closable={closable}
      onClose={onClose}
      open={visible}
    >
      {goBack && (
        <Button
          key="more-go-back"
          type="text"
          onClick={onClose}
          icon={<LeftOutlined />}
          style={{
            display: 'flex',
            alignItems: 'center',
            color: 'var(--gray-1)',
            marginBottom: 8,
          }}
        >
          {f('header.menu.drawer.go_back')}
        </Button>
      )}

      <>{children}</>
    </Drawer>
  );
};

MainDrawer.defaultProps = {
  goBack: true,
};

export default MainDrawer;
