import React from 'react';

import {
  DashboardOutlined,
  FileAddOutlined,
  CloudOutlined,
  DatabaseOutlined,
  FileOutlined,
  AlertOutlined,
  BookOutlined,
  UnorderedListOutlined,
  CheckCircleOutlined,
  PieChartOutlined,
} from '@ant-design/icons';

import { OfflineIcon, UsersIcon } from '../CustomIcons';

type Props = {
  iconName: string;
};

const MenuIcon: React.FC<Props> = ({ iconName }) => {
  switch (iconName) {
    case 'DashboardOutlined':
      return <DashboardOutlined />;
    case 'FileAddOutlined':
      return <FileAddOutlined />;
    case 'CloudOutlined':
      return <CloudOutlined />;
    case 'DatabaseOutlined':
      return <DatabaseOutlined />;
    case 'FileOutlined':
      return <FileOutlined />;
    case 'AlertOutlined':
      return <AlertOutlined />;
    case 'BookOutlined':
      return <BookOutlined />;
    case 'UnorderedListOutlined':
      return <UnorderedListOutlined />;
    case 'CheckCircleOutlined':
      return <CheckCircleOutlined />;
    case 'OfflineIcon':
      return <OfflineIcon />;
    case 'UsersIcon':
      return <UsersIcon />;
    case 'PieChartOutlined':
      return <PieChartOutlined />;
    default:
      return <FileOutlined />;
  }
};

export default MenuIcon;
