import React from 'react';

import { useIntl } from 'react-intl';

import styles from '@styles/Footer/address.module.scss';

const Address: React.FC = () => {
  const { formatMessage } = useIntl();
  const f = (id: string) => formatMessage({ id });

  const info = f('theboat_app.footer.information.info');
  const address = f('theboat_app.footer.information.address');
  const imageAlt = f('theboat_app.footer.logo.image.alt');

  return (
    <div className={styles.container}>
      <div className={styles.container__logo}>
        <img src="/images/theboatapp-logo.png" alt={imageAlt} />
      </div>

      <p className={styles.container__info} dangerouslySetInnerHTML={{ __html: info }} />

      <p className={styles.container__address} dangerouslySetInnerHTML={{ __html: address }} />
    </div>
  );
};

export default Address;
