import { Menu, Typography } from 'antd';
import { useCallback, useMemo, FC } from 'react';
import { useIntl } from 'react-intl';
import styles from 'sailor-ui/styles/Header.module.scss';
import { moreMenuItems } from './moreMenuItems';

const { Title } = Typography;

const MoreMenu: FC = () => {
  const { formatMessage } = useIntl();
  const f = useCallback((id: string): string => formatMessage({ id }), [formatMessage]);

  const menu = useMemo(() => <Menu items={moreMenuItems(f)} className={styles.moreMenu} />, [f]);

  return (
    <>
      <Title level={5} style={{ color: 'var(--gray-1)', marginLeft: 16 }}>
        More
      </Title>

      {menu}
    </>
  );
};

export default MoreMenu;
