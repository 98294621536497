import Head from 'next/head';
import { useRouter } from 'next/router';
import packageJson from '../package.json';
import { useConfig } from 'providers';

import { MetaDataInterface } from '../interfaces/metaData';

const MetaData: React.FC<MetaDataInterface> = ({
  title,
  image = 'https://apps-static-files.s3.eu-west-1.amazonaws.com/app-assets/images/theboatapp-og-image.jpg',
  description = 'Marine Data Cloud',
  author = 'MDC Team',
  copyright = 'Marinedatacloud',
  siteName = 'Marine Data Cloud LTD',
  keyword = [],
  generator = 'NextJS',
  robots = 'all',
  ogType = 'website',
  twitterCard = 'summary',
}) => {
  const titleWithBrandName = `${title} – TheBoatApp`;
  const { asPath } = useRouter();
  const { theboatLandingUrl } = useConfig();

  const url = 'https://theboatapp.com';
  const canonicalUrl = url + asPath;

  return (
    <Head>
      <title>{titleWithBrandName}</title>
      <meta charSet="utf-8" />
      <meta name="app:version" content={packageJson?.version || 'unknown'} />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <link rel="manifest" href="/favicons/manifest.json" />
      <link rel="author" href="humans.txt" />
      <meta name="title" content={titleWithBrandName} />
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />
      <meta name="author" content={author} />
      <meta name="keyword" content={keyword.join(', ')} />
      <meta name="copyright" content={copyright} />
      <meta name="locale" content="en" />
      <meta name="generator" content={generator} />
      <meta name="base_url" content={theboatLandingUrl} />
      <meta name="robots" content={robots} />
      <meta name="language" content="en" />
      <meta name="twitter:widgets:csp" content="on" />
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:title" content={titleWithBrandName} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:url" content="https://theboatapp.com/" />
      <meta property="og:type" content={ogType} />
      <meta property="og:title" content={titleWithBrandName} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content="https://theboatapp.com/" />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:locale" content="en" />
    </Head>
  );
};

export default MetaData;
