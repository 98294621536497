/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import { useConfig } from 'providers';
import { FC, useMemo } from 'react';
import { MetaDataInterface } from '../interfaces/metaData';
import Footer from './Footer';
import Header from './Header';
import MetaData from './MetaData';
import SetupLayout from './SetupLayout';

type Props = {
  children: JSX.Element;
  meta: MetaDataInterface;
  useHeader?: boolean;
  useFooter?: boolean;
};

const Layout: FC<Props> = ({ children, meta, useHeader, useFooter }) => {
  const { zohoRumKey, zohoSalesIqWidgetCode } = useConfig();

  const { googleAnalyticId, googleAdsId } = useConfig();

  const renderGA = useMemo<boolean>(() => !!googleAnalyticId && !!googleAnalyticId.length, [googleAnalyticId]);
  const renderGAds = useMemo<boolean>(() => !!googleAdsId && !!googleAdsId.length, [googleAdsId]);

  return (
    <SetupLayout>
      <MetaData {...meta} />
      {useHeader && <Header />}

      {children}

      {useFooter && <Footer />}

      {renderGA && <script async defer src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticId}`} />}
      {renderGAds && <script async defer src={`https://www.googletagmanager.com/gtag/js?id=${googleAdsId}`} />}

      {renderGA && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                  dataLayer.push(arguments);
                }
                gtag('js', new Date());
                gtag('config', '${googleAnalyticId}');
                gtag('config', '${renderGAds}');
              `,
          }}
        />
      )}

      {zohoRumKey && zohoRumKey.length && (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,r,k,h,m){
            if(w.performance && w.performance.timing && w.performance.navigation) {
                w[r] = w[r] || function(){(w[r].q = w[r].q || []).push(arguments)}
                h=d.createElement('script');h.async=true;h.setAttribute('src',s+k);
                d.getElementsByTagName('head')[0].appendChild(h);
                (m = window.onerror),(window.onerror = function (b, c, d, f, g) {
                m && m(b, c, d, f, g),g || (g = new Error(b)),(w[r].q = w[r].q || []).push(["captureException",g]);})
            }
        })(window,document,'//static.site24x7rum.com/beacon/site24x7rum-min.js?appKey=','s247r','${zohoRumKey}');`,
          }}
        />
      )}
      <script
        dangerouslySetInnerHTML={{
          __html:
            "(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');",
        }}
      />
      <script
        data-domains="theboatdb.com, theboatapp.com, app.theboatdb.com, account.marinedatacloud.com, account.marinedatacloud.com"
        async
        src="https://r.wdfl.co/rw.js"
        data-rewardful="df7ca5"
      />

      {zohoSalesIqWidgetCode && zohoSalesIqWidgetCode.length && (
        <script
          type="text/javascript"
          id="zsiqchat"
          dangerouslySetInnerHTML={{
            __html: `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${zohoSalesIqWidgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);`,
          }}
        />
      )}
    </SetupLayout>
  );
};

Layout.defaultProps = {
  useHeader: true,
  useFooter: true,
};

export default Layout;
