import React from 'react';
import Link from 'next/link';

import { useIntl } from 'react-intl';
import { RightOutlined } from '@ant-design/icons';

import styles from '@styles/Footer/menu.module.scss';
import MenuIcon from './MenuIcon';

type Props = {
  items: number[];
  itemKey: string;
};

const Menu: React.FC<Props> = ({ itemKey, items }) => {
  const { formatMessage } = useIntl();
  const f = (id: string) => formatMessage({ id });

  const title = f(`theboat_app.footer.menu.${itemKey}.title`);
  const badge = f(`theboat_app.footer.menu.${itemKey}.badge`);
  const limitContent = f(`theboat_app.footer.menu.${itemKey}.limit`);
  const limit = limitContent !== 'null' ? parseInt(limitContent, 10) : 10;

  const more = {
    url: f(`theboat_app.footer.menu.${itemKey}.more.url`),
    text: f(`theboat_app.footer.menu.${itemKey}.more.text`),
  };

  return (
    <div className={styles.container}>
      <div className={styles.container__title}>
        <h4 dangerouslySetInnerHTML={{ __html: title }} />
        {badge !== 'null' && <p className={styles.container__badge}>{badge.toUpperCase()}</p>}
      </div>

      <ul className={styles.container__menu}>
        {items.map((item, index) => {
          const text = f(`theboat_app.footer.menu.${itemKey}.item.${item}.text`);
          const url = f(`theboat_app.footer.menu.${itemKey}.item.${item}.url`);
          const icon = f(`theboat_app.footer.menu.${itemKey}.item.${item}.icon`);
          const itemBadge = f(`theboat_app.footer.menu.${itemKey}.item.${item}.badge`);
          let linkAttributes = {};

          if (index > limit) {
            return null;
          }

          if (url.startsWith('http') && !url.includes('/checklists/')) {
            linkAttributes = {
              target: '_blank',
              rel: 'noopener',
            };
          }

          if (index < limit) {
            return (
              <li key={url} className={styles.container__item}>
                <div className={styles.container__item__icon}>{icon !== 'null' && <MenuIcon iconName={icon} />}</div>
                <Link href={url} {...linkAttributes}>
                  <span>{text}</span>
                  {itemBadge !== 'null' && <p className={styles.container__badge}>{itemBadge}</p>}
                </Link>
              </li>
            );
          }

          return (
            <li key={url} className={styles.container__item__more}>
              <Link href={more.url || '/'} {...linkAttributes}>
                {more.text} <RightOutlined style={{ fontSize: '13px' }} />
                {itemBadge !== 'null' && <p className={styles.container__badge}>{itemBadge}</p>}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Menu;
