import { DashboardOutlined, LaptopOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Grid, Menu, MenuProps } from 'antd';
import Link from 'next/link';
import { useConfig } from 'providers';
import { useMemo } from 'react';
import { HelpCenter } from 'sailor-ui/HelpCenter';
import { UserDropdown, UserDropdownProps } from 'sailor-ui/UserDropdown';
import styles from 'sailor-ui/styles/Header.module.scss';

const RightMenu: React.FC = () => {
  const { isAuthenticated } = useAuth0();

  const { lg: responsive, xl } = Grid.useBreakpoint();
  const { theboatAppUrl, mdcAccountUrl } = useConfig();

  const menuItems = useMemo(
    (): MenuProps['items'] => [
      {
        key: 'login',
        label: (
          <Button type="link" href={theboatAppUrl} block className={styles.loginButtonDrawer}>
            Login
          </Button>
        ),
      },
      {
        key: 'signUp',
        label: (
          <Link href={'/register'} className="ant-btn ant-btn-default ant-btn-block ant-btn-special">
            Sign up for free
          </Link>
        ),
      },
    ],
    [theboatAppUrl],
  );

  const userMenuItems = useMemo<UserDropdownProps['menuItems']>(
    () => [
      {
        key: 'my-profile',
        title: 'Account',
        items: [
          {
            key: 'profile',
            url: mdcAccountUrl,
            icon: <UserOutlined />,
            text: 'Profile',
            target: '_blank',
          },
          {
            key: 'activity-log',
            url: `${theboatAppUrl}/activity-log`,
            icon: <LaptopOutlined />,
            text: 'Activity log',
          },
          {
            key: 'user-settings',
            url: `${mdcAccountUrl}/settings`,
            icon: <SettingOutlined />,
            text: 'Settings',
            target: '_blank',
          },
        ],
      },
    ],
    [mdcAccountUrl, theboatAppUrl],
  );

  if (responsive) {
    return (
      <div className={styles.rightMenuDesktop}>
        {!isAuthenticated ? (
          <>
            <Button type="link" href={theboatAppUrl} className={styles.loginButtonDesktop}>
              Login
            </Button>
            <Link href={'/register'} className="ant-btn ant-btn-default  ant-btn-special">
              Sign up for free
            </Link>
          </>
        ) : (
          <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
            <Button href={theboatAppUrl} icon={<DashboardOutlined />}>
              {xl && 'Dashboard'}
            </Button>
            <HelpCenter app="tba" />
            <UserDropdown menuItems={userMenuItems} renderLink={Link} isNextJs />
          </div>
        )}
      </div>
    );
  }

  if (isAuthenticated) {
    return null;
  }

  return <Menu mode="inline" items={menuItems} />;
};

export default RightMenu;
