import { MenuProps } from 'antd';
import Link from 'next/link';

export const blogsMenuItems = (f: (id: string) => string): MenuProps['items'] => [
  {
    key: 'blog',
    label: (
      <Link href={f('header.menu.left.blogs.blog.url')} target="_blank" rel="noopener">
        {f('header.menu.left.blogs.blog')}
      </Link>
    ),
  },
  {
    key: 'news',
    label: (
      <Link href={f('header.menu.left.blogs.news.url')} target="_blank" rel="noopener">
        {f('header.menu.left.blogs.news')}
      </Link>
    ),
  },
];
