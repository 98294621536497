import React from 'react';

import { useIntl } from 'react-intl';

import {
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  LinkedinIcon,
  RedditIcon,
  YoutubeIcon,
  AppleLogoIcon,
  AndroidStoreIcon,
} from '@icons';

import styles from '@styles/Footer/header.module.scss';
import DownloadApp from './DownloadApp';

const Header: React.FC = () => {
  const { formatMessage } = useIntl();
  const f = (id: string) => formatMessage({ id });

  const socialItems = [
    {
      url: 'https://www.facebook.com/marinedatacloud/',
      icon: <FacebookIcon />,
    },
    {
      url: 'https://twitter.com/MarineDataCloud',
      icon: <TwitterIcon />,
    },
    {
      url: 'https://www.linkedin.com/company/marinedatacloud',
      icon: <LinkedinIcon />,
    },
    {
      url: 'https://www.reddit.com/user/MarineDataCloud/',
      icon: <RedditIcon />,
    },
    {
      url: 'https://www.instagram.com/marinedatacloud',
      icon: <InstagramIcon />,
    },
    {
      url: 'https://www.youtube.com/channel/UCR5zZQwHQTE9uhDXdHgrzcA',
      icon: <YoutubeIcon />,
    },
  ];

  const downloadButtons = [
    {
      title: f('theboat_app.footer.download.ios.title'),
      text: f('theboat_app.footer.download.ios.title'),
      url: 'https://apps.apple.com/app/id6443831708?platform=iphone',
      icon: <AppleLogoIcon />,
    },
    {
      title: f('theboat_app.footer.download.android.title'),
      text: f('theboat_app.footer.download.android.text'),
      url: 'https://play.google.com/store/apps/details?id=com.marinedatacloud.theboatapp',
      icon: <AndroidStoreIcon />,
    },
  ];

  return (
    <div className={styles.container}>
      <ul className={styles.container__menu}>
        {socialItems.map((item, index) => {
          const { url, icon } = item;

          return (
            <li className={styles.container__item} key={url}>
              <a href={url} target="_blank" rel="noopener noreferrer" className={styles.container__item__link}>
                {icon}
              </a>
            </li>
          );
        })}
      </ul>

      <div className={styles.container__download}>
        {downloadButtons.map((item, index) => (
          <DownloadApp key={item.url} {...item} />
        ))}
      </div>
    </div>
  );
};

export default Header;
