import React, { useEffect, useMemo } from 'react';
import { useConfig, useUserSetupStatus } from 'providers';
import { SETUP_OPTIONS } from 'mdc-constants';
import { useAuth0 } from '@auth0/auth0-react';
import { useReferralToken } from '@marine/utils';

interface Props {
  children: React.ReactNode;
}

const SetupLayout: React.FC<Props> = ({ children }) => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  const { referralToken } = useReferralToken();

  const queryParams = useMemo(() => {
    if (referralToken) {
      return `?via=${referralToken}`;
    }

    return undefined;
  }, [referralToken]);

  const { setupStatusState } = useUserSetupStatus();

  const { mdcAccountUrl } = useConfig();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (user && !user.email_verified) {
        const query = queryParams || '';

        window.location.assign(`${mdcAccountUrl}/email-confirmation${query}`);
      }

      if (setupStatusState === SETUP_OPTIONS.NOT_STARTED || setupStatusState === SETUP_OPTIONS.SELECT_BOAT) {
        const query = queryParams
          ? `${queryParams}&returnTo=${window.location.href}`
          : `?returnTo=${window.location.href}`;

        window.location.assign(`${mdcAccountUrl}/setup-account${query}`);
      }
    }

    return () => {};
  }, [isAuthenticated, user, setupStatusState, isLoading, queryParams]);

  return <>{children}</>;
};

export default SetupLayout;
