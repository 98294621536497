import React from 'react';

import styles from '@styles/Footer/main.module.scss';
import Menu from './Menu';
import Header from './Header';
import Address from './Address';
import Copyright from './Copyright';
import FeaturesMenu from './FeaturesMenu';

const Footer: React.FC = () => {
  const menu = {
    2: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    3: [0, 1],
    4: [0, 1, 2, 3],
    5: [0],
    6: [0, 1, 2, 3, 4],
    7: [0, 1, 2],
  };

  return (
    <footer className={styles.container}>
      <Header />

      <div className={styles.container__menu}>
        <FeaturesMenu />

        {Object.keys(menu).map((key, index) => (
          <Menu key={key} itemKey={key} items={menu[key]} />
        ))}
      </div>

      <Address />

      <Copyright />
    </footer>
  );
};

export default Footer;
