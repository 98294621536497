type MenuKeys = {
  key: string;
  validPaths: string[];
  startsWith?: string;
}[];

export const menuKeys: MenuKeys = [
  {
    key: 'features',
    validPaths: [
      '/features/dashboard-app',
      '/features/boat-offline-app',
      '/features/user-defined-fields',
      '/features/user-to-user-sharing',
      '/features/boat-app-cloud-storage',
      '/features/logbook-software-boats-yachts',
      '/features/boat-yacht-inventory-software',
      '/features/boat-yacht-task-management-app',
      '/features/boat-yacht-document-management-app',
      '/features/boat-yacht-checklists-app',
      '/features/boat-yacht-alert-management-app',
      '/features/report-generating-software-boats-yachts',
    ],
    startsWith: '/features',
  },
  {
    key: 'checklists',
    validPaths: ['/checklists'],
  },
  {
    key: 'pricing',
    validPaths: ['/pricing'],
  },
  {
    key: 'more',
    validPaths: ['/how-it-works', '/our-story', '/careers', '/changelog'],
  },
];
